<template>
  <base-form-layout
    :title="`${action} ${$tc('product.part', 1)}`"
    @submit="onSubmit"
  >
    <v-form ref="form">
      <ItemSalesQuotationEditForm
        :item="itemSalesQuotation"
        :item-id="itemSalesQuotationId"
      />
    </v-form>
  </base-form-layout>
</template>

<script>
import ItemSalesQuotationEditForm from './components/ItemSalesQuotationEditForm'
import { ITEM_SALES_QUOTATION } from '@/models/item.sales.quotation'
import { FormMixin } from '@/mixins/form.mixin'
import { DocumentMixin } from '@/mixins/document.mixin.js'
import { mapActions } from 'vuex'

export default {
  name: 'ItemSalesQuotationEdit',
  components: {
    ItemSalesQuotationEditForm
  },
  mixins: [FormMixin, DocumentMixin],
  props: {
    orderId: {
      type: String,
      required: true
    },
    itemSalesQuotationId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      itemSalesQuotation: ITEM_SALES_QUOTATION(),
      isLoading: true
    }
  },

  computed: {
    action() {
      return this.$t(this.itemSalesQuotationId ? 'actions.edit' : 'actions.new')
    }
  },
  created() {
    this.fetchData()
  },

  methods: {
    ...mapActions({
      addNotification: 'notification/add'
    }),
    async fetchData() {
      this.isLoading = true
      this.itemSalesQuotation.attributes.clientId = await this.fetchClientId()
      if (this.itemSalesQuotationId)
        this.itemSalesQuotation = await this.fetchItemSalesQuotation()
      else {
        this.itemSalesQuotation.attributes.orderId = this.orderId
      }
      this.isLoading = false
    },
    async fetchItemSalesQuotation() {
      const { data } = await this.$store.dispatch(
        'itemSalesQuotation/fetch',
        this.itemSalesQuotationId
      )
      return data
    },
    async fetchClientId() {
      const response = await this.$store.dispatch('party/filter', {
        orderIds: this.orderId
      })
      if (response.length) return response[0].attributes.userId
    },
    async onSubmit() {
      this.beforeSubmit = false
      try {
        const action = this.itemSalesQuotationId ? 'update' : 'create'
        const response = await this.$store.dispatch(
          `itemSalesQuotation/${action}`,
          this.itemSalesQuotation
        )
        await this.createDocument(response.id, 'Item', 'Attachment')
        this.addNotification({
          status: '200',
          color: 'success',
          time: 2000,
          messages: { title: `Successfully ${action}d` }
        })
        this.$router.go(-1)
      } catch (error) {
        if (!error.response) return
        const {
          status,
          data: { errors }
        } = error.response
        return this.addNotification({
          status,
          messages: errors,
          color: 'error',
          time: 10000
        })
      }
    }
  }
}
</script>
