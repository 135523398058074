<template>
  <div>
    <h6 class="text-h6 mb-5">{{ $t('common.details') }}</h6>

    <v-row dense>
      <v-col cols="12" md="6"
        ><base-selector
          v-if="partsCategories.length"
          v-model="item.attributes.name"
          :items="
            partsCategories.map(c => ({
              text: $tc(`inventory.categories.${c.value}`, 1),
              value: c.value
            }))
          "
          :label="$t('common.name') + '(*)'"
          :placeholder="$t('inventory.categories.back')"
      /></v-col>

      <v-col cols="12" md="6">
        <base-field-input
          v-model="item.attributes.description"
          :label="$t('item.description')"
          :placeholder="$t('inventory.categories.back') + '-D'"
      /></v-col>

      <v-col cols="12" md="6"
        ><BaseAutocomplete
          v-model="item.attributes.customAttributes.specie"
          :items="species"
          :label="$t('item.specie')"
          :placeholder="$t('species.Sapele')"
          persistent-hint
          return-object
          :item-text="item => $t(`species.${item.text}`)"
          :hint="item.attributes.customAttributes.specie.value"
      /></v-col>

      <v-col cols="12" md="6"
        ><base-selector
          v-model="item.attributes.customAttributes.category"
          :items="categories.map(c => $t(`item.${c}`))"
          :label="$tc('item.category', 1)"
      /></v-col>

      <v-col cols="12" md="6"
        ><base-selector
          v-model="item.attributes.customAttributes.type"
          :items="types"
          :label="$t('item.type')"
      /></v-col>
      <v-col cols="12" md="6"
        ><base-selector
          v-model="item.attributes.customAttributes.ncCode"
          :items="ncCodes"
          :label="$t('item.ncCode')"
      /></v-col>
      <v-col v-if="!itemId" cols="12" md="6" offset="6">
        <QuantityField filled :item="item"
      /></v-col>
    </v-row>

    <h6 class="text-h6 mb-5">{{ $t('item.dimensions') }}</h6>
    <v-divider class="mb-8"></v-divider>
    <!-- <ItemSalesQuotationEditFormMeasurement :item="item" /> -->
    <ItemEditFormMeasurement :item="item" :pack-option="true" />
    <h6 class="text-h6 mb-5">{{ $tc('document.attachment', 2) }}</h6>
    <v-divider class="mb-8"></v-divider>
    <base-file-input />
  </div>
</template>

<script>
// import ItemSalesQuotationEditFormMeasurement from './ItemSalesQuotationEditFormMeasurement'
import ItemEditFormMeasurement from '@/components/ItemEditFormMeasurement'
import QuantityField from '@/components/QuantityField'
import {
  SPECIES,
  PARTS_CATEGORIES,
  INVENTORY_CATEGORIES,
  TYPES,
  NC_CODES,
  CATEGORIES
} from '@/common/terms'
export default {
  name: 'ItemSalesQuotationEditForm',
  components: {
    // ItemSalesQuotationEditFormMeasurement,
    ItemEditFormMeasurement,
    QuantityField
  },
  props: {
    itemId: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      partsCategories: [...PARTS_CATEGORIES, ...INVENTORY_CATEGORIES],
      species: SPECIES,
      types: TYPES,
      ncCodes: NC_CODES,
      categories: CATEGORIES
    }
  }
}
</script>
